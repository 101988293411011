.vrdevice-stock-container {
  .vrdevice-stock-btn {
    margin: 10px 0 10px;
    display: flex;
    justify-content: flex-end;

    .stock-btn-space {
      margin-left: 12px;
    }
  }

  .bottom-btn {
    position: fixed;
    width: calc(100% + 40px);
    margin-left: -50px;
    bottom: 0px;
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 10px;
    z-index: 999;
  }

  .device-info-box {
    margin-top: 10px;
  }
}