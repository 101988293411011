.modulePublic-Apply-container {
  .custom-sn-form-item {
    .ant-form-item-control {
      line-height: normal !important;
    }
  }

  .sn-container {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;

    .sn-box {
      display: flex;
      flex-direction: column;
      min-height: 100px;

      .sn-item:last-child {
        border-bottom: none;
      }

      .sn-text {
        flex: 1;
        font-size: 14px;
        color: #333;
        margin: 0 5px;
      }

      .sn-close {
        font-size: 14px;
        cursor: pointer;
      }

      .sn-close:hover {
        cursor: pointer;
      }
    }
  }

  .device-info-content {
    font-size: 14px;

    p {
      display: flex;
    }

    .label {
      display: inline-block;
      min-width: 120px;
      text-align: right;
      margin-right: 18px;
    }
  }

  .loading-content {
    display: flex;
    justify-content: center;
  }
}